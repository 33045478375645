.trigger-hover {
  @apply cursor-pointer;

  .hover-content {
    @apply hidden;
  }

  &:hover {
    .hover-content {
      @apply inline-block;
    }
  }
}

.integration-example code span:nth-child(1) .xml.hljs-tag:nth-child(6) {
  background: #2a4867;
}

.lightbox {
  @apply fixed z-10 left-0 right-0 top-0 bottom-0;

  &-bg {
    @apply bg-gray-900 opacity-75 absolute top-0 bottom-0 left-0 right-0 z-10;
  }

  &-close {
    @apply absolute top-0 right-0 h-8 w-8 z-40;

    margin-top: -1rem;
    margin-right: -1rem;

    img,
    video {
      @apply h-8 w-8;
    }
  }

  &-content {
    @apply relative z-20 h-full pt-6 mx-auto;

    width: 90vw;

    .video-content {
      height: 70vh;

      video {
        width: 100%;
        height: auto;
        max-height: 100%;
      }
    }

    & > div > img,
    & > div > video {
      &.withTransition {
        transition: all 0.3s ease-in-out;
        will-change: transform;
      }

      &.toleft {
        transform: translateX(-100vh);
        will-change: transform;
      }

      &.toright {
        transform: translateX(100vh);
        will-change: transform;
      }
    }

    .photos-list-item-name {
      padding: 1rem;
      font-size: 1.25rem;
      line-height: 1.25rem;
      max-height: initial;
      margin: auto;
      font-weight: 500;
      bottom: 3rem;
    }
  }
}

.submenu {
  @apply absolute rounded-md shadow-lg w-56 z-10;

  & > div {
    @apply rounded-md bg-white shadow-xl;
  }

  .row {
    @apply py-1;

    a {
      @apply block px-4 py-2 text-sm leading-5 text-gray-700;

      &:hover {
        @apply bg-gray-100 text-gray-900;
      }

      &:focus {
        @apply outline-none bg-gray-100 text-gray-900;
      }
    }
  }

  .sep {
    @apply border-t border-gray-100;
  }
}

.lds-ellipsis div {
  position: absolute;
  top: 30%;
  width: 16.25%;
  height: 33%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 10%;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 10%;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 40%;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 70%;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.corner-ribbon {
  width: 20rem;
  position: absolute;
  top: 3.5rem;
  left: -5rem;
  text-align: center;
  line-height: 3rem;
  letter-spacing: 1px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky {
  position: fixed;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

/* Different positions */

.corner-ribbon.top-left {
  top: 3.5rem;
  left: -5rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right {
  top: 3.5rem;
  right: -5rem;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left {
  top: auto;
  bottom: 3.5rem;
  left: -5rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
  top: auto;
  right: -5rem;
  bottom: 3.5rem;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
