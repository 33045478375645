@import 'tailwindcss/base';

/* Start purging... */

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Stop purging... */

@import './_fonts';
@import './_animations';
@import './app';
